<!--
 * @Author: jiang
 * @Date: 2021-07-13 11:18:17
 * @Description: 领导工作周报
-->
<template>
  <el-scrollbar
    y
    view-style="padding: 0 15px 15px"
  >
    <div class="g-bg g-p-20">
      <el-form>
        <el-form-item style="vertical-align: bottom;">
          <c-btn-search
            :show="['create']"
            @create="onCreate"
          ></c-btn-search>
        </el-form-item>
      </el-form>
      <el-table :data="tableData">
        <el-table-column
          label="姓名"
          prop="user.name"
        ></el-table-column>
        <el-table-column
          label="所在部门"
          prop="department.name"
        ></el-table-column>
        <el-table-column
          label="内容"
          prop="content"
          show-overflow-tooltip=""
        ></el-table-column>
        <el-table-column
          label="时间"
          prop="schedule_date"
          width="160px"
        ></el-table-column>
        <el-table-column
          label="审核状态"
          prop="examine_status"
          width="160px"
        >
          <template slot-scope="{ row }">
            <div>
              {{ row.examine_status | dict('reportAuditStatusDict') }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          width="200px"
        >
          <template slot-scope="{ row }">
            <c-btn-table
              :show="['info', row.examine_status === 'not_examine' ? 'update' : '', row.examine_status === 'not_examine' ? 'delete' : '' ]"
              @info="onInfo(row)"
              @update="onUpdate(row)"
              @delete="onDelete(row)"
            ></c-btn-table>
          </template>
        </el-table-column>
      </el-table>

      <b-pagination
        :page="page"
        @change="fetchList"
      ></b-pagination>
    </div>

    <b-dialog
      ref="detailDialog"
      width="800px"
    >
      <detail-view
        :data="formData"
        @success="onDetailSuccess"
      ></detail-view>
    </b-dialog>

    <b-dialog
      ref="infoDialog"
      width="700px"
    >
      <info-view :row="currentRow"></info-view>
    </b-dialog>
  </el-scrollbar>
</template>

<script>
import DetailView from '../components/detail.vue'
import InfoView from '../components/info.vue'
import { getMyCreateReportPage, deleteReport } from '@/api/report/index'

export default {
  components: {
    DetailView,
    InfoView,
  },
  data() {
    return {
      tableData: [],
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
      formData: {},
      currentRow: {},
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      const params = {
        page: this.page.current,
      }
      getMyCreateReportPage(params).then(res => {
        this.tableData = res.data
        this.page.total = res.total
      })
    },
    onRefresh() {
      this.page.current = 1
      this.fetchList()
    },
    onDetailSuccess() {
      this.onRefresh()
      this.$refs.detailDialog.close()
    },
    onCreate() {
      this.formData = {
        department_id: this.$store.getters['user/deptId'],
        user_id: this.$store.getters['user/id'],
        schedule_date: this.$tools.dayjs().format('YYYY-MM-DD'),
        content: '',
      }
      this.$refs.detailDialog.open()
    },
    onInfo(row) {
      this.currentRow = row
      this.$refs.infoDialog.open()
    },
    onUpdate(row) {
      this.formData = {
        id: row.id,
        department_id: row.department_id,
        user_id: row.user_id,
        schedule_date: row.schedule_date,
        content: row.content,
      }
      this.$refs.detailDialog.open()
    },
    onDelete(row) {
      this.$confirm('确定要删除当前项吗', { type: 'warning' })
        .then(() => {
          return deleteReport(row.id)
        })
        .then(() => {
          this.$message.success('删除成功')
          this.onRefresh()
        })
        .catch(() => {})
    },
  },
}
</script>
